import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

import { clsNames, useUser } from 'unno-comutils'
import { Icon } from 'unno-comutils/ui'

import User from './userbar'
import { MenuConsole } from './service/menu'

function LayoutMenu () {
    const user = useUser()
    const history = useHistory()
    const location = useLocation()

    const linkClick = (e: any) => {
        if (!e.ctrlKey) {
            e.preventDefault()
            history.push(e.currentTarget.getAttribute('href'))
        }
    }

    if (location.pathname.startsWith('/console') && user.admin) {
        return <div className="layout-menu">
            {MenuConsole.map(m => <a key={'menu_' + m.url} href={m.url}
                                     className={clsNames('_item', location.pathname.startsWith(m.url) && '-active')} onClick={linkClick}>
                <Icon name={m.icon}/>
                <span className={'_text'}>{m.text}</span>
            </a>)}
        </div>
    }

    //if (state.appPage === 'manga_read')
    //    return <div className="layout-title">{state.appTitle}</div>
    return <></>
}

export default function Layout (props: { title?: string, children: any }) {
    const location = useLocation()
    const history = useHistory()

    const linkClick = (e: any) => {
        if (!e.ctrlKey) {
            e.preventDefault()
            history.push(e.currentTarget.getAttribute('href'))
        }
    }

    const layoutClass = useMemo(() => {
        const P = location.pathname
        if (P.startsWith('/manga/') || (P.startsWith('/h/') && !P.endsWith('/h/'))) return 'layout-reader'
        if (P.startsWith('/console/')) return 'layout-console'
        return ''
    }, [location])

    return <div className={clsNames('layout', layoutClass)}>
        <LayoutMenu/>
        <User/>
        <div className="layout-content">
            {props.children}
        </div>
    </div>
}

/* <div className="layout-top">
    <div className="layout-menu">
        <Icon href="/home" name={'home'} onClick={linkClick}/>
    </div>
    <LayoutMenu state={state}/>
    <User/>
</div>*/
