import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { dConfirm, tError, useUser } from 'unno-comutils'
import { login, removeToken, saveToken } from 'unno-comutils/connect'
import { Input } from 'unno-comutils/form'
import { Icon, Button, Modal } from 'unno-comutils/ui'

function UserProfilePopup (props: any) {
    const wrapperRef = useRef<any>()

    const onClickOutside = (event: any) => {
        if (wrapperRef && !wrapperRef.current.contains(event.target) && props.onClose) {
            props.onClose()
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside)
        return () => document.removeEventListener('mousedown', onClickOutside)
    }, [])

    const divProp = {
        ref: wrapperRef,
        children: props.children,
        className: '_profile'
    }
    return <div {...divProp}/>
}

function User () {
    const user = useUser()

    const [dialog, setDialog] = useState(false)

    const [menuOpen, setMenuOpen] = useState(false)

    const [waitLogin, setWaitLogin] = useState(false)
    const [inputUser, setInputUser] = useState('')
    const [inputPassword, setInputPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const history = useHistory()

    const loginForm = () => {
        if (inputUser === '' || inputPassword === '') {
            tError('LOGIN_INPUT_ERROR')
        }
        else {
            setWaitLogin(true)
            login(inputUser, inputPassword)
                .then((d: any) => {
                    if (d.ok) {
                        //dispatch({ type: 'USER_LOGIN', user: d.user })
                        setDialog(false)
                        saveToken(d.token)
                    }
                    else {
                        tError('LOGIN_FAIL')
                    }
                })
                .finally(() => setWaitLogin(false))
        }
    }

    const logout = () => {
        dConfirm('Logout ?')
            .then(yes => {
                if (yes) {
                    removeToken()
                    //dispatch({ type: 'USER_LOGOUT' })
                }
            })
    }

    const lineClick = (e: any) => {
        if (!e.ctrlKey) {
            e.preventDefault()
            if (menuOpen) setMenuOpen(false)
            history.push(e.currentTarget.getAttribute('href'))
        }
    }

    if (!!user) {
        return <div className="layout-user">
            <div className="_box" onClick={() => setMenuOpen(true)}>
                {user.photo
                    ? <div className="_avatar" style={{ backgroundImage: `url(${user.photo})` }}/>
                    : <Icon name={'user-circle'} className="_avatar"/>}
                <div className="_name">{user.name}</div>
            </div>
            {menuOpen && <UserProfilePopup onClose={() => setMenuOpen(false)}>
                <div className="_link">
                    <Icon button href="/profile" name={'user-circle'} onClick={lineClick}/>
                    {user.admin && <Icon button href="/console/manga" name={'laptop-code'} onClick={lineClick}/>}
                    <Icon button name={'power-off'} onClick={() => logout()}/>
                </div>
            </UserProfilePopup>}
        </div>
    }

    return <div className="layout-user">
        <Icon button name={'band-aid'} onClick={() => setDialog(true)}/>
        <Modal className="login-dialog" open={dialog} onClose={() => setDialog(false)}>
            <div className="_logo">
                <h5>Login</h5>
            </div>
            <div className="_form">
                {waitLogin ? <div className="_wait"><Icon name={'spinner-third'} spin/></div> : <>
                    <Input icon={'user'} lg className="mt-1"
                           value={inputUser} onChange={v => setInputUser(v)}/>
                    <Input type={showPassword ? 'text' : 'password'} icon={'key'} lg className="mt-3"
                           value={inputPassword} onChange={v => setInputPassword(v)}
                           apppend={<Icon name={showPassword ? 'ellipsis-h-alt' : 'ellipsis-h'} button onClick={() => setShowPassword(!showPassword)}/>}/>
                    <Button primary fullWidth onClick={loginForm}>LOGIN</Button>
                </>}
            </div>
            {/*
            <div className="_or"><span>or Connect with</span></div>
            <div className="_connect">
                <Button onClick={() => loginFirebase('gg')}>Google</Button>
                <Button onClick={() => loginFirebase('fb')}>Facebook</Button>
                <Button onClick={() => loginFirebase('tw')}>Twitter</Button>
            </div>
            */}
        </Modal>
    </div>
}

export default User
