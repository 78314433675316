import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import L from '@loadable/component'

import { RoutePrivate } from 'unno-comutils/Route'
import Wait from 'unno-comutils/Wait'

const fallback = <Wait icon={'fan'}/>

const Home = L(() => import(/* webpackChunkName: "home" */'../pages/home'), { fallback })

const Manga = L(() => import(/* webpackChunkName: "manga" */'../pages/manga'), { fallback })
const MangaRead = L(() => import(/* webpackChunkName: "manga-read" */'../pages/mangaRead'), { fallback })

const HManga = L(() => import(/* webpackChunkName: "manga-read" */'../pages/hmanga'), { fallback })
const HMangaRead = L(() => import(/* webpackChunkName: "manga-read" */'../pages/hmangaRead'), { fallback })

const ConsoleManga = L(() => import(/* webpackChunkName: "console.manga" */'../pages/console/manga'), { fallback })
const ConsoleMangaPage = L(() => import(/* webpackChunkName: "console.manga-page" */'../pages/console/mangaPage'), { fallback })
const ConsoleHManga = L(() => import(/* webpackChunkName: "console.hmanga" */'../pages/console/h'), { fallback })
const ConsoleHMangaPage = L(() => import(/* webpackChunkName: "console.hmanga-page" */'../pages/console/hPage'), { fallback })

function Router () {
    return <Switch>
        <Route exact path="/" children={<Redirect to="/home"/>}/>
        <Route exact path="/home" children={<Home/>}/>

        <Route exact path="/manga/:key" children={<Manga/>}/>
        <Route exact path="/manga/:key/:chapter" children={<MangaRead/>}/>

        <Route exact path="/h" children={<HManga/>}/>
        <Route exact path="/h/:key" children={<HMangaRead/>}/>

        <RoutePrivate needRole="__admin" exact path="/console/manga" children={<ConsoleManga/>}/>
        <RoutePrivate needRole="__admin" exact path="/console/manga/:id" children={<ConsoleMangaPage/>}/>
        <RoutePrivate needRole="__admin" exact path="/console/h" children={<ConsoleHManga/>}/>
        <RoutePrivate needRole="__admin" exact path="/console/h/:id" children={<ConsoleHMangaPage/>}/>

        <RoutePrivate needRole="__admin" exact path="/console/h" children={<ConsoleManga/>}/>
        <RoutePrivate needRole="__admin" exact path="/console/h/:id" children={<ConsoleMangaPage/>}/>
    </Switch>
}

export default Router
