import React from 'react'
import ReactDOM from 'react-dom/client'

import 'unno-comutils/style/core.css'
import './style/app.scss'

import { unregister } from './service/worker'

import App from './app'

export const fallback = <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}><img src="https://files.un-no.net/static/loading.svg" alt="loading"/></div>

const root = document.getElementById('root')
if (root) ReactDOM.createRoot(root).render(<App/>)

unregister()
