import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AppContext, useAppStateContext } from 'unno-comutils'
import { Wait } from 'unno-comutils/ui'
import { User } from 'unno-comutils/utils'
import { ToastContainer } from 'unno-comutils/Dialog'
import { checkLogin, removeToken } from 'unno-comutils/connect'

import Routing from './service/router'

import Layout from './layout'

export default function App () {
    const [wait, setWait] = useState(0)

    const appState = useAppStateContext()

    useEffect(() => {
        setTimeout(() => {
            checkLogin().then((d: any) => {
                if (d.ok) {
                    appState.setUser(new User(d.user))
                }
                else {
                    removeToken()
                }
            }).finally(() => setWait(2))
        }, 200)
    }, [])

    if (wait < 2) return <Wait color={wait ? 'blue' : 'pink'}/>

    return <AppContext.Provider value={appState}>
        <BrowserRouter>
            <Layout>
                <Routing/>
            </Layout>
        </BrowserRouter>
        <ToastContainer/>
    </AppContext.Provider>
}
